import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ScoreBadge from './ScoreBadge';

interface CategoryReportProps {
    label: string;
    score: number;
    maxScore: number;
    recommendations: string[];
}

export default function CategoryReport(
    props: CategoryReportProps
): ReactElement {
    const { t } = useTranslation();
    return (
        <div>
            <h1 className="font-regular text-2xl mt-28">
                {t('pages.report.category-report.title')}
                <br />
                <span className="font-bold">{props.label}</span>
            </h1>
            <div className="mt-2">
                <ScoreBadge score={props.score} maxScore={props.maxScore} />
            </div>
            {props.recommendations.length ? (
                <>
                    <h2 className="text-xl font-semibold mt-4">
                        Recommendations
                    </h2>
                    <ul className="mt-2 ml-6">
                        {Object.values(props.recommendations).map(
                            (recommendation: string, i: number) => {
                                return (
                                    <li className="list-disc" key={i}>
                                        {recommendation}
                                    </li>
                                );
                            }
                        )}
                    </ul>
                </>
            ) : (
                <p className=" mt-4 italic">
                    {t('pages.report.category-report.recommendations-fallback')}
                </p>
            )}
        </div>
    );
}
