import { ReactElement } from 'react';

interface QuestionIndexProps {
    categoryTotalQuestions: number;
    currentQuestion: number;
}
export default function QuestionIndex(props: QuestionIndexProps): ReactElement {
    return (
        <div className="bg-brown-main px-4 py-1 text-white mr-4 rounded-full">
            {props.currentQuestion}/{props.categoryTotalQuestions}
        </div>
    );
}
