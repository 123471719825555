import { ReactElement, ReactNode } from 'react';

interface BreadCrumbLinkProps {
    children: ReactNode;
    currentLink: boolean;
    disabled?: boolean;
    questionIndex: number;
    switchCategory: (questionId: number) => void;
}

export default function BreadCrumbLink(
    props: BreadCrumbLinkProps
): ReactElement {
    return (
        <div
            className={`${props.currentLink ? 'font-bold' : ''} ${
                props.disabled
                    ? 'text-brown-light border-brown-main cursor-not-allowed text-center'
                    : ''
            } menu__link`}
            onClick={() => {
                if (!props.disabled) {
                    props.switchCategory(props.questionIndex);
                }
            }}
        >
            {props.children}
        </div>
    );
}
