import { useEffect, FormEvent, useState } from 'react';
import Navbar from '../components/navbar/Navbar';
import gradient from '../assets/images/layout/gradient.svg';
import companyFormIllustration from '../assets/images/illustrations/company-form.svg';
import Footer from '../components/footer/Footer';
import PrimaryButton from '../components/button/PrimaryButton';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    CompanyActivityFieldInput,
    CompanyFormContent,
    CompanySizeInput,
} from '../types/CSRSurvey';
import axios from 'axios';

export default function CompanyFormPage(): React.ReactElement {
    const { t } = useTranslation();

    const [formContent, setFormContent] = useState<CompanyFormContent>({
        'company-name': '',
        'company-size': '',
        'activity-field': '',
        'contact-email': '',
        'wants-contact': false,
    });

    const [fieldsErrors, setFieldsErrors] = useState<object>({});
    const [generalformError, setGeneralFormError] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();

    const onSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        setFieldsErrors({});
        setGeneralFormError(false);
        if (e.target) {
            void axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_ENDPOINT}/response`,
                timeout: 4000,
                data: {
                    language_ios639: location.state.surveyData.data.lang,
                    activity_field_code: formContent['activity-field'],
                    company_size_code: formContent['company-size'],
                    company_name: formContent['company-name'],
                    email: formContent['contact-email'],
                    to_contact: formContent['wants-contact'],
                    question_answers: location.state.userAnswers,
                },
            })
                .then(() => {
                    localStorage.removeItem('answersSelection');
                    navigate('/thanks', { replace: true });
                })
                .catch(function (error) {
                    if (error.response)
                        setFieldsErrors(error.response.data.errors);
                    else {
                        // If the error is not related to fields content, show general error
                        setGeneralFormError(true);
                    }
                });
        }
    };
    useEffect(() => {
        if (location.state === null) {
            navigate('/survey', { replace: true });
        }
    }, []);

    return (
        <div className="page">
            <Navbar />
            <div className="page__content lg:grid-cols-5">
                <div className="col-span-3 3xl:col-span-2">
                    <h1 className="font-light text-4xl lg:mt-8 leading-10">
                        <Trans i18nKey="pages.about-you.title">
                            Consultez votre{' '}
                            <span className="font-bold">
                                Rapport de maturité complet
                            </span>
                        </Trans>
                    </h1>
                    <p className="mt-8 font-bold">
                        {t('pages.about-you.description')}
                    </p>
                    <form className="font-bold" onSubmit={onSubmit}>
                        <div className="mt-12">
                            <label htmlFor="company-name">
                                {t('pages.about-you.form.company-name')}
                            </label>
                            <input
                                className={`block border ${
                                    'company_name' in fieldsErrors
                                        ? 'border-red'
                                        : ''
                                } mt-2 w-full rounded-lg h-12 px-4 font-light`}
                                name="company-name"
                                type="text"
                                onChange={(e) => {
                                    setFormContent((formContent) => ({
                                        ...formContent,
                                        ...{
                                            'company-name': e.target.value,
                                        },
                                    }));
                                }}
                            />
                            <p
                                className={`${
                                    'company_name' in fieldsErrors
                                        ? 'block'
                                        : 'hidden'
                                } text-xs text-red mt-1`}
                            >
                                {t(
                                    'pages.about-you.form.error.company-name-error'
                                )}
                            </p>
                        </div>
                        <div className="mt-6">
                            <label htmlFor="company-size">
                                {t('pages.about-you.form.company-size')}
                            </label>
                            <select
                                className={`border ${
                                    'company_size_code' in fieldsErrors
                                        ? 'border-red'
                                        : ''
                                } mt-2 w-full   rounded-lg font-light flex justify-between my-auto h-12 pl-4`}
                                name="company-size"
                                onChange={(e) => {
                                    setFormContent((formContent) => ({
                                        ...formContent,
                                        ...{
                                            'company-size': e.target.value,
                                        },
                                    }));
                                }}
                            >
                                <option value="">
                                    {t('pages.about-you.form.placeholder')}
                                </option>
                                {location.state?.surveyData.data.inputs_data.company_sizes.map(
                                    (el: CompanySizeInput) => (
                                        <option key={el.code} value={el.code}>
                                            {el.size_name}
                                        </option>
                                    )
                                )}
                            </select>
                            <p
                                className={`${
                                    'company_size_code' in fieldsErrors
                                        ? 'block'
                                        : 'hidden'
                                } text-xs text-red mt-1`}
                            >
                                {t(
                                    'pages.about-you.form.error.company-size-error'
                                )}
                            </p>
                        </div>
                        <div className="mt-6">
                            <label htmlFor="activity-field">
                                {t('pages.about-you.form.activity-field')}
                            </label>
                            <select
                                className={`border ${
                                    'activity_field_code' in fieldsErrors
                                        ? 'border-red'
                                        : ''
                                } mt-2 w-full   rounded-lg font-light flex justify-between my-auto h-12 pl-4`}
                                name="activity-field"
                                onChange={(e) => {
                                    setFormContent((formContent) => ({
                                        ...formContent,
                                        ...{
                                            'activity-field': e.target.value,
                                        },
                                    }));
                                }}
                            >
                                <option value="">
                                    {t('pages.about-you.form.placeholder')}
                                </option>
                                {location.state?.surveyData.data.inputs_data.activity_fields.map(
                                    (el: CompanyActivityFieldInput) => (
                                        <option key={el.code} value={el.code}>
                                            {el.field_name}
                                        </option>
                                    )
                                )}
                            </select>
                            <p
                                className={`${
                                    'activity_field_code' in fieldsErrors
                                        ? 'block'
                                        : 'hidden'
                                } text-xs text-red mt-1`}
                            >
                                {t(
                                    'pages.about-you.form.error.activity-field-error'
                                )}
                            </p>
                        </div>
                        <div className="mt-6">
                            <label htmlFor="contact-email">
                                {t('pages.about-you.form.contact-email')}
                            </label>
                            <input
                                className={`block border ${
                                    'email' in fieldsErrors ? 'border-red' : ''
                                } mt-2 w-full rounded-lg h-12 px-4 font-light`}
                                name="contact-email"
                                type="email"
                                onChange={(e) => {
                                    setFormContent((formContent) => ({
                                        ...formContent,
                                        ...{
                                            'contact-email': e.target.value,
                                        },
                                    }));
                                }}
                            />
                            <p
                                className={`${
                                    'email' in fieldsErrors ? 'block' : 'hidden'
                                } text-xs text-red mt-1`}
                            >
                                {t('pages.about-you.form.error.email-error')}
                            </p>
                        </div>
                        <div className="mt-6 flex">
                            <input
                                className="block border mt-2 h-8 w-8 rounded-lg px-4 font-light text-brown-main"
                                name="wants-contact"
                                type="checkbox"
                                onChange={(e) => {
                                    setFormContent((formContent) => ({
                                        ...formContent,
                                        ...{
                                            'wants-contact': e.target.checked,
                                        },
                                    }));
                                }}
                            />

                            <label
                                htmlFor="wants-contact"
                                className="ml-6 my-auto"
                            >
                                {t('pages.about-you.form.wants-contact')}
                            </label>
                        </div>
                        <p
                            className={`${
                                generalformError ? 'block' : 'hidden'
                            } text-xs text-red mt-6`}
                        >
                            {t('pages.about-you.form.error.general-error')}
                        </p>
                        <PrimaryButton className="mt-10">
                            {t('pages.about-you.form.submit-button')}
                        </PrimaryButton>
                    </form>
                </div>
                <div className="col-span-2 3xl:col-span-3 mt-10 lg:mt-0 mx-auto w-1/2 lg:w-full 3xl:w-1/2">
                    <img
                        className="mx-auto w-11/12"
                        src={companyFormIllustration}
                        alt=""
                    />
                </div>
            </div>
            <img
                className="-z-50 w-full fixed bottom-0 right-0 select-none"
                src={gradient}
                alt="Background color gradient"
            />
            <Footer />
        </div>
    );
}
