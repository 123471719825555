import Navbar from '../components/navbar/Navbar';
import gradient from '../assets/images/layout/gradient.svg';
import thanksIllustration from '../assets/images/illustrations/thanks.svg';
import Footer from '../components/footer/Footer';
import PrimaryButton from '../components/button/PrimaryButton';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import externalLinkIcon from '../assets/images/external-link.svg';
import SecondaryButton from '../components/button/SecondaryButton';

export default function ConfirmationPage(): React.ReactElement {
    const { t } = useTranslation();

    return (
        <div className="page">
            <Navbar />
            <div className="page__content lg:grid-cols-5">
                <div className="col-span-2">
                    <h1 className="font-light text-4xl lg:mt-8 leading-10">
                        {t('pages.confirmation.title')}
                    </h1>
                    <p className="mt-8 font-bold">
                        {t('pages.confirmation.description')}
                    </p>
                    <Link to={'/'}>
                        <PrimaryButton className="mt-10">
                            {t('pages.confirmation.cta-secondary')}
                        </PrimaryButton>
                    </Link>
                    <a
                        href="https://thepositiveproject.eco/contact/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <SecondaryButton className="mt-4 flex justify-center items-center">
                            <img
                                src={externalLinkIcon}
                                alt="External link icon"
                            />
                            <span className="ml-2">
                                {t('pages.confirmation.cta-primary')}
                            </span>
                        </SecondaryButton>
                    </a>
                </div>
                <div className="col-span-3 mt-10 lg:mt-0 mx-auto w-1/2">
                    <img
                        className="mx-auto w-11/12"
                        src={thanksIllustration}
                        alt=""
                    />
                </div>
            </div>
            <img
                className="-z-50 w-full fixed bottom-0 right-0 select-none"
                src={gradient}
                alt="Background color gradient"
            />
            <Footer />
        </div>
    );
}
