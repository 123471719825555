import { ReactElement, useState } from 'react';
import logo from '../../assets/images/logo.png';
import { Link, useLocation } from 'react-router-dom';
import MenuLink from './MenuLink';
import LanguageSelect from './LanguageSelect';
import burgerMenuIcon from '../../assets/images/burger-menu.svg';
import externalLinkIcon from '../../assets/images/external-link.svg';
import { useTranslation } from 'react-i18next';

export default function Navbar(): ReactElement {
    const { t } = useTranslation();
    const location = useLocation();
    const [isMenuToggled, setToggleMenuState] = useState<boolean>(false);
    return (
        <>
            <div className="navbar">
                <div className="px-4 flex justify-between">
                    <Link to={'/'}>
                        <img
                            className="w-60"
                            src={logo}
                            alt="Logo of The Positive Project"
                        />
                    </Link>
                    {/* margin-right to remove when the logo will be adapted */}
                    <button
                        className="lg:invisible mr-4"
                        onClick={() => {
                            setToggleMenuState(!isMenuToggled);
                        }}
                    >
                        <img src={burgerMenuIcon} alt="Burger menu icon" />
                    </button>
                </div>
                {/* margin-right to remove when the logo will be adapted */}
                <div
                    className={`${
                        isMenuToggled
                            ? 'visible opacity-100 translate-y-0'
                            : 'invisible lg:visible opacity-0 lg:opacity-100 -translate-y-2 lg:translate-y-0'
                    } menu__link-container menu__container`}
                >
                    <MenuLink
                        src="/about"
                        isCurrentLink={location.pathname === '/about'}
                    >
                        {t('navbar.about')}
                    </MenuLink>
                    <MenuLink
                        src="https://thepositiveproject.eco/contact/"
                        external={true}
                        target="_blank"
                    >
                        <img src={externalLinkIcon} alt="External link icon" />
                        <span className="ml-2">{t('navbar.contact-us')}</span>
                    </MenuLink>
                    <LanguageSelect
                        closeMenu={() => {
                            setToggleMenuState(false);
                        }}
                    ></LanguageSelect>
                </div>
            </div>
            <div
                className={`menu__negative-space ${
                    isMenuToggled
                        ? 'z-30 visible lg:invisible opacity-20'
                        : 'invisible opacity-0'
                }`}
                onClick={() => {
                    setToggleMenuState(false);
                }}
            ></div>
        </>
    );
}
