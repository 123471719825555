import { ReactElement } from 'react';

interface ScoreBadgeProps {
    score: number;
    maxScore: number;
}

export default function ScoreBadge(props: ScoreBadgeProps): ReactElement {
    return (
        <p className="px-4 py-1 bg-[rgb(136,193,113,.25)] rounded-full inline-block text-[#76A862] text-xl">
            {props.score}/{props.maxScore}
        </p>
    );
}
