import { ReactElement, ReactNode } from 'react';

interface primaryButtonProps {
    children: ReactNode;
    className?: string;
    disabled?: boolean;
    onClick?: () => void;
}

export default function PrimaryButton(props: primaryButtonProps): ReactElement {
    return (
        <button
            className={`${
                props.className ?? ''
            } home-link bg-brown-main hover:bg-brown-main-hover text-white disabled:bg-brown-light disabled:text-brown-main-hover`}
            onClick={props.onClick}
            disabled={props.disabled ?? false}
        >
            {props.children}
        </button>
    );
}
