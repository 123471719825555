import { ReactElement } from 'react';

interface secondaryButtonProps {
    children: React.ReactNode;
    className: string;
}

export default function SecondaryButton(
    props: secondaryButtonProps
): ReactElement {
    return (
        <button
            className={`${
                props.className ?? ''
            } home-link border-brown-main border-1 hover:bg-brown-light bg-white`}
        >
            {props.children}
        </button>
    );
}
