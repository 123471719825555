import * as React from 'react';
import Navbar from '../components/navbar/Navbar';
import gradient from '../assets/images/layout/gradient.svg';
import aboutFirstIllustration from '../assets/images/illustrations/about-1.svg';
import aboutSecondIllustration from '../assets/images/illustrations/about-2.svg';
import externalLinkIcon from '../assets/images/external-link.svg';
import Footer from '../components/footer/Footer';
import PrimaryButton from '../components/button/PrimaryButton';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SecondaryButton from '../components/button/SecondaryButton';

export default function AboutPage(): React.ReactElement {
    const { t } = useTranslation();
    return (
        <div className="page">
            <Navbar />
            <div className="page__content lg:grid-cols-5">
                <div className="col-span-2">
                    <h1 className="font-regular text-4xl lg:mt-8 leading-10">
                        <Trans i18nKey="pages.about.title">
                            La responsabilité sociétale des entreprises (RSE),{' '}
                            <span className="font-bold">
                                qu’est-ce que c’est ?
                            </span>
                        </Trans>
                    </h1>
                    <p className="mt-8">
                        <Trans i18nKey="pages.about.description"></Trans>
                    </p>
                    <Link to={'/survey'}>
                        <PrimaryButton className="mt-10">
                            {t('pages.about.button-start')}
                        </PrimaryButton>
                    </Link>
                </div>
                <div className="col-span-3 mt-10 lg:mt-0 mx-auto w-3/4">
                    <img
                        className="mx-auto w-11/12"
                        src={aboutFirstIllustration}
                        alt=""
                    />
                </div>
                <div className="col-span-2">
                    <h1 className="font-regular text-4xl mt-16 lg:mt-8 leading-10">
                        {t('pages.about.credits-title')}
                    </h1>
                    <p className="font-light mt-6">
                        <Trans i18nKey="pages.about.credits-description">
                            &apos;Cet outil a pu être développé grâce aux
                            compétences de l&apos;Institut d’Ingénierie des
                            Médias (MEI) et au{' '}
                            <a
                                href="https://www.innosuisse.ch/inno/fr/home.html"
                                target="_blank"
                                className="underline"
                                rel="noreferrer"
                            >
                                fond de soutien à l&apos;innovation
                                d&apos;Innosuisse.
                            </a>
                        </Trans>
                    </p>
                    <a
                        href="https://thepositiveproject.eco/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <SecondaryButton className="mt-8 flex justify-center items-center">
                            <img
                                src={externalLinkIcon}
                                alt="External link icon"
                            />
                            <span className="ml-2">
                                {t('pages.about.tpp-link')}
                            </span>
                        </SecondaryButton>
                    </a>
                    <a
                        href="https://heig-vd.ch/rad/instituts/mei"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <SecondaryButton className="mt-4 flex justify-center items-center">
                            <img
                                src={externalLinkIcon}
                                alt="External link icon"
                            />
                            <span className="ml-2">
                                {t('pages.about.mei-link')}
                            </span>
                        </SecondaryButton>
                    </a>
                    <p className="font-light mt-6">
                        {t('pages.about.illustrations-credits')}:{' '}
                        <a
                            href="https://www.drawkit.com/"
                            target="_blank"
                            className="underline"
                            rel="noreferrer"
                        >
                            DrawKit
                        </a>
                    </p>
                </div>
                <div className="col-span-3 mt-10 lg:mt-0 mx-auto w-3/4">
                    <img
                        className="mx-auto w-11/12"
                        src={aboutSecondIllustration}
                        alt=""
                    />
                </div>
            </div>
            <img
                className="-z-50 w-full fixed bottom-0 right-0 select-none"
                src={gradient}
                alt="Background color gradient"
            />
            <Footer />
        </div>
    );
}
