import * as React from 'react';
import Navbar from '../components/navbar/Navbar';
import gradient from '../assets/images/layout/gradient.png';
import homeIllustration from '../assets/images/illustrations/homepage.svg';
import Footer from '../components/footer/Footer';
import PrimaryButton from '../components/button/PrimaryButton';
import SecondaryButton from '../components/button/SecondaryButton';
import timerIcon from '../assets/images/timer.svg';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function HomePage(): React.ReactElement {
    const { t } = useTranslation();
    return (
        <div className="page">
            <Navbar />
            <div className="page__content lg:grid-cols-5">
                <div className="col-span-2">
                    <h1 className="font-regular text-4xl lg:mt-8 leading-10">
                        <Trans i18nKey="pages.home.title">
                            Bienvenue sur le{' '}
                            <span className="font-bold">
                                Questionnaire RSE TPP
                            </span>
                        </Trans>
                    </h1>
                    <p className="mt-8">{t('pages.home.description')}</p>
                    <p className="font-bold text-lg mt-4 flex">
                        <img src={timerIcon} alt="Timer icon" />
                        <span className="ml-2">{t('pages.home.timing')}</span>
                    </p>
                    <Link to={'/survey'}>
                        <PrimaryButton className="mt-10">
                            {t('pages.home.button-start')}
                        </PrimaryButton>
                    </Link>
                    <Link to={'/about'}>
                        <SecondaryButton className="mt-4">
                            {t('pages.home.button-csr-about')}
                        </SecondaryButton>
                    </Link>
                </div>
                <div className="col-span-3 mt-10 lg:mt-0 mx-auto w-3/4">
                    <img
                        className="mx-auto w-11/12"
                        src={homeIllustration}
                        alt=""
                    />
                </div>
            </div>
            <img
                className="-z-50 w-full fixed bottom-0 right-0 select-none"
                src={gradient}
                alt="Background color gradient"
            />
            <Footer />
        </div>
    );
}
