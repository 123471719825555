import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CSRLevel } from '../../types/CSRSurvey';
import ScoreBadge from './ScoreBadge';

interface ReportIntroProps {
    averageScore: number;
    maxScore: number;
}

export default function ReportIntro(props: ReportIntroProps): ReactElement {
    const { t } = useTranslation();
    let level: CSRLevel = 'good';
    if (props.averageScore < 2.5) {
        level = 'bad';
    } else if (props.averageScore < 4.25) {
        level = 'medium';
    }
    return (
        <>
            <div className="text-[#76A862] bg-white text-2xl shadow-lg rounded-lg p-6 mt-4">
                <h2 className="font-bold">
                    {t(`pages.report.main-score.${level}.title`)}
                </h2>
                <div className="flex items-center mt-2">
                    <h3>Score global:</h3>
                    <span className="ml-2">
                        <ScoreBadge
                            score={props.averageScore}
                            maxScore={props.maxScore}
                        />
                    </span>
                </div>
            </div>
            <p className="mt-10 breal-normal">
                {t(`pages.report.main-score.${level}.description`)}
            </p>
        </>
    );
}
