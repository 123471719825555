import SurveyNavbar from '../components/navbar/SurveyNavbar';
import gradient from '../assets/images/layout/gradient.svg';
import Footer from '../components/footer/Footer';
import { useTranslation } from 'react-i18next';
import QuestionIndex from '../components/survey/QuestionIndex';
import PrimaryButton from '../components/button/PrimaryButton';
import Spinner from '../components/Spinner';
import AnswerForm from '../components/survey/AnswerForm';
import { useState, ReactElement, ReactNode, useEffect } from 'react';
import { CSRSurvey, UserAnswer } from '../types/CSRSurvey';
import BreadCrumbLink from '../components/navbar/BreadCrumbLink';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function SurveyPage(): ReactElement {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();

    const [surveyData, setSurveyData] = useState<CSRSurvey | null>();
    const [fetchError, setFetchError] = useState<boolean>(false);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [userAnswers, setUserAnswers] = useState<UserAnswer[]>([]);
    const [currentCategoryIndex, setCurrentCategoryIndex] = useState<number>(0);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);

    useEffect(() => {
        fetchSurveyData();
    }, [i18n.resolvedLanguage]);

    const fetchSurveyData = (): void => {
        setFetchError(false);
        setIsFetching(true);
        void axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_ENDPOINT}/survey?lang=${i18n.resolvedLanguage}`,
            timeout: 4000,
        })
            .then((response) => {
                setIsFetching(false);
                setSurveyData(response.data);
            })
            .catch(() => {
                setIsFetching(false);
                setFetchError(true);
            });
    };

    // [] --> useEffect called only on component mount
    useEffect(() => {
        if (localStorage.answersSelection) {
            setUserAnswers(JSON.parse(localStorage.answersSelection));
        }
    }, []);

    const updateUserAnswers = (newAnswer: UserAnswer): void => {
        const newAnswers = [
            ...userAnswers.filter(
                (savedAnswer) =>
                    savedAnswer.question_id !== newAnswer.question_id
            ),
            newAnswer,
        ];
        setUserAnswers(newAnswers);
        localStorage.answersSelection = JSON.stringify(newAnswers);
    };

    const currentCategory = surveyData?.data.categories[currentCategoryIndex];
    const currentQuestionUserAnswers: number[] =
        userAnswers.filter((userAnswer) => {
            return (
                currentCategory?.questions[currentQuestionIndex].id ===
                userAnswer.question_id
            );
        })[0]?.answers ?? [];

    const switchToNextQuestion = (): void => {
        if (
            surveyData &&
            currentQuestionIndex ===
                surveyData.data.categories[currentCategoryIndex].questions
                    .length -
                    1
        ) {
            if (
                surveyData &&
                currentCategoryIndex === surveyData.data.categories.length - 1
            ) {
                navigate('/about-you', { state: { userAnswers, surveyData } });
            } else {
                switchCategory(currentCategoryIndex + 1);
            }
        } else {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };

    const switchCategory = (categoryId: number): void => {
        // Switch to specified category
        setCurrentCategoryIndex(categoryId);
        // Set the question index to be the first of the category
        setCurrentQuestionIndex(0);
    };

    const categoryQuestionsId = (categoryId: number): number[] => {
        const categoryQuestions: number[] = [];
        surveyData?.data.categories
            .find((category) => category.id === categoryId)
            ?.questions.forEach((question) => {
                categoryQuestions.push(question.id);
            });
        return categoryQuestions;
    };

    const isCategoryFullyAnswered = (categoryId: number): boolean => {
        const userQuestionsAnswered: number[] = [];
        userAnswers.forEach((userAnswer) => {
            if (userAnswer.answers.length)
                userQuestionsAnswered.push(userAnswer.question_id);
        });
        return categoryQuestionsId(categoryId).every((question) => {
            return userQuestionsAnswered.includes(question);
        });
    };

    const nextCategoryIdToAnswer = (): number => {
        const categories = surveyData?.data.categories;
        if (categories?.length) {
            for (let i = 0; i < categories.length; i++) {
                if (!isCategoryFullyAnswered(categories[i].id)) {
                    return categories[i].id;
                }
            }
            return categories.length;
        }
        return 0;
    };

    const breadcrumbLinks: ReactNode = surveyData?.data.categories.map(
        (category, index) => {
            return (
                <BreadCrumbLink
                    currentLink={currentCategory?.id === category.id}
                    questionIndex={index}
                    disabled={category.id > nextCategoryIdToAnswer()}
                    switchCategory={switchCategory}
                    key={category.id}
                >
                    {category.title}
                </BreadCrumbLink>
            );
        }
    );

    return (
        <div className="page">
            <SurveyNavbar>{breadcrumbLinks}</SurveyNavbar>
            <div className="mb-auto p-10 lg:p-20 grid 2xl:mx-40 3xl:mx-80">
                <Spinner isLoading={isFetching} />
                {!fetchError && surveyData && (
                    <>
                        <div className="questionHeader flex items-start">
                            <QuestionIndex
                                currentQuestion={currentQuestionIndex + 1}
                                categoryTotalQuestions={
                                    currentCategory?.questions.length ?? 0
                                }
                            />
                            <h1 className="text-2xl h-full">
                                {currentCategory?.title}
                            </h1>
                        </div>
                        <p className="mt-4 font-bold">
                            {
                                currentCategory?.questions[currentQuestionIndex]
                                    .text
                            }
                        </p>
                        <AnswerForm
                            questionId={
                                currentCategory?.questions[currentQuestionIndex]
                                    .id ?? 0
                            }
                            answers={
                                currentCategory?.questions[currentQuestionIndex]
                                    .answers ?? []
                            }
                            isMultipleSelect={
                                currentCategory?.questions[currentQuestionIndex]
                                    .is_multiple_select ?? true
                            }
                            userPreviousSelections={currentQuestionUserAnswers}
                            onAnswerUpdate={updateUserAnswers}
                        />
                        <PrimaryButton
                            className={`${
                                !currentQuestionUserAnswers.length
                                    ? 'cursor-not-allowed'
                                    : ''
                            } mt-10 lg:w-1/3 lg:mx-auto lg:mt-12 xl:mt-24 2xl:mt-28`}
                            onClick={switchToNextQuestion}
                            disabled={!currentQuestionUserAnswers.length}
                        >
                            {t('pages.survey.button_next')}
                        </PrimaryButton>
                    </>
                )}
                {fetchError && (
                    <div className="mx-auto text-center">
                        <p className="text-2xl">
                            {t('pages.survey.fetch-error')}
                        </p>
                        <PrimaryButton
                            className="mt-10"
                            onClick={fetchSurveyData}
                        >
                            {t('pages.survey.reload-button')}
                        </PrimaryButton>
                    </div>
                )}
            </div>
            <img
                className="-z-50 w-full fixed bottom-0 right-0 select-none"
                src={gradient}
                alt="Background color gradient"
            />
            <Footer />
        </div>
    );
}
