import { ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface menuLinkProps {
    children: ReactNode;
    src: string;
    isCurrentLink?: boolean;
    target?: string;
    external?: boolean;
}

export default function MenuLink(props: menuLinkProps): ReactElement {
    return (
        <div>
            {/*test if true to exclude nullish case*/}
            {props.external === true ? (
                <a
                    className={`menu__link ${
                        props.isCurrentLink === true ? 'font-bold' : ''
                    }`}
                    href={props.src}
                    target={props.target}
                >
                    {props.children}
                </a>
            ) : (
                <Link
                    className={`menu__link ${
                        props.isCurrentLink === true ? 'font-bold' : ''
                    }`}
                    to={{ pathname: props.src }}
                >
                    {props.children}
                </Link>
            )}
        </div>
    );
}
