import { Answer, UserAnswer } from '../../types/CSRSurvey';
import CheckboxInput from './CheckboxInput';
import RadioInput from './RadioInput';

import { ReactElement, ReactNode } from 'react';

interface answerFormProps {
    questionId: number;
    answers: Answer[];
    isMultipleSelect: boolean;
    userPreviousSelections: number[];
    onAnswerUpdate: (newAnswer: UserAnswer) => void;
}
export default function AnswerForm(props: answerFormProps): ReactElement {
    const fallbackId =
        props.answers.filter((answer) => answer.is_fallback)[0]?.id ?? null;

    const handleAnswerChange = (answerId?: number): void => {
        let newAnswerSelectionIds: number[] = props.userPreviousSelections;
        if (answerId) {
            if (props.isMultipleSelect) {
                if (newAnswerSelectionIds.includes(answerId)) {
                    newAnswerSelectionIds = newAnswerSelectionIds.filter(
                        (id) => id !== answerId
                    );
                } else {
                    if (newAnswerSelectionIds.includes(fallbackId)) {
                        newAnswerSelectionIds = [];
                    }
                    if (answerId === fallbackId) {
                        newAnswerSelectionIds = [answerId];
                    } else {
                        newAnswerSelectionIds = [
                            ...newAnswerSelectionIds,
                            answerId,
                        ];
                    }
                }
            } else {
                newAnswerSelectionIds = [answerId];
            }
        }
        props.onAnswerUpdate({
            question_id: props.questionId,
            answers: newAnswerSelectionIds,
        });
    };

    const answersInputs: ReactNode = props.answers.map((answer) => {
        if (props.isMultipleSelect) {
            return (
                <CheckboxInput
                    answer={answer}
                    isChecked={props.userPreviousSelections.includes(answer.id)}
                    handleChange={handleAnswerChange}
                    key={answer.id}
                />
            );
        } else {
            return (
                <RadioInput
                    answer={answer}
                    isChecked={props.userPreviousSelections.includes(answer.id)}
                    handleChange={handleAnswerChange}
                    key={answer.id}
                />
            );
        }
    });
    return (
        <form className="lg:flex lg:items-stretch flex-wrap lg:mt-10 lg:justify-evenly">
            {answersInputs}
        </form>
    );
}
