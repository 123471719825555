import { ReactElement, useEffect, useRef } from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    RadarController,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

interface SpiderChartProps {
    data: number[];
    labels: Array<string | string[]>;
}

export default function SpiderChart(props: SpiderChartProps): ReactElement {
    ChartJS.register(
        RadarController,
        RadialLinearScale,
        PointElement,
        LineElement,
        Filler,
        Tooltip,
        Legend
    );

    const triggerTooltip = (chart: ChartJS | null): void => {
        const tooltip = chart?.tooltip;

        if (!tooltip) {
            return;
        }

        if (tooltip.getActiveElements().length > 0) {
            tooltip.setActiveElements([], { x: 0, y: 0 });
        } else {
            const { chartArea } = chart;

            tooltip.setActiveElements(
                [
                    {
                        datasetIndex: 0,
                        index: 0,
                    },
                ],
                {
                    x: (chartArea.left + chartArea.right) / 2,
                    y: (chartArea.top + chartArea.bottom) / 2,
                }
            );
        }

        chart.update();
    };

    useEffect(() => {
        //Need to set a timeout to make sure the chart is rendered before triggering the tooltip
        setTimeout(() => {
            triggerTooltip(chartRef.current);
        }, 1000);
    }, props.labels);

    const chartRef = useRef<ChartJS>(null);
    const delayed = useRef<boolean>(false);

    const data = {
        labels: props.labels,
        datasets: [
            {
                data: props.data,
                backgroundColor: 'rgba(136, 193, 113, 0.25)',
                borderColor: 'rgba(118, 168, 98, 1)',
                pointBackgroundColor: 'rgba(118, 168, 98, 1)',
                borderWidth: 2,
            },
        ],
    };

    const options: any = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                padding: 16,
                bodyFont: {
                    size: 14,
                },
                displayColors: false,
                backgroundColor: '#6A5752',
                bodyAlign: 'center' as 'center',
                titleColor: '#FFFFFF',
                callbacks: {
                    label: (context: any) => {
                        return `${context.dataset.data[context.dataIndex]} / 5`;
                    },
                },
            },
        },
        animation: {
            animation: {
                onComplete: () => {
                    delayed.current = true;
                    triggerTooltip(chartRef.current);
                },
                delay: (context: any) => {
                    let delay = 0;
                    if (
                        context.type === 'data' &&
                        context.mode === 'default' &&
                        !delayed
                    ) {
                        delay = context.dataIndex * 1000;
                    }
                    return delay;
                },
            },
        },
        scales: {
            r: {
                grid: {
                    circular: true,
                },
                min: 0,
                max: 5,
                ticks: {
                    // forces step size to be 50 units
                    stepSize: 1,
                },
            },
        },
    };

    return (
        <>
            <Chart
                ref={chartRef}
                data={data}
                options={options}
                type={'radar'}
            />
        </>
    );
}
