import { Answer } from '../../types/CSRSurvey';
import inputUnselected from '../../assets/images/input-unselected.svg';
import radioSelected from '../../assets/images/radio-selected.svg';
import { ReactElement } from 'react';

interface radioInputProps {
    answer: Answer;
    isChecked?: boolean;
    handleChange: (answerId: number) => void;
}

export default function RadioInput(props: radioInputProps): ReactElement {
    return (
        <div className="mt-10" key={props.answer.id}>
            <input
                className="appearance-none hidden"
                type="radio"
                id={`answer-${+props.answer.id}`}
                name="answerSelection"
                value={props.answer.id}
                checked={props.isChecked ?? false}
                onChange={() => {
                    props.handleChange(props.answer.id);
                }}
            />
            <label
                className={`${
                    props.isChecked ? 'shadow-inner' : ''
                } lg:min-h-250 2xl:min-h-300 text-center grid content-center border-brown-main border p-4 rounded-xl lg:h-full lg:w-64 2xl:w-80 bg-white cursor-pointer`}
                htmlFor={`answer-${+props.answer.id}`}
            >
                {props.isChecked ? (
                    <img
                        className="absolute block m-4 lg:m-0"
                        src={radioSelected}
                        alt="Selected input status icon"
                    />
                ) : (
                    <img
                        className="absolute block m-4 lg:m-0"
                        src={inputUnselected}
                        alt="Unselected input status icon"
                    />
                )}
                <p className="mt-10 mb-10 mx-10">{props.answer.text}</p>
            </label>
        </div>
    );
}
