import { ReactElement, useEffect, useState } from 'react';
import dropArrowIcon from '../../assets/images/drop-arrow.svg';
import { useTranslation } from 'react-i18next';

interface LanguageSelectProps {
    closeMenu: () => void;
}

export default function LanguageSelect(
    props: LanguageSelectProps
): ReactElement {
    const { i18n } = useTranslation();
    const [isToggled, setToggleMenuState] = useState<boolean>(false);
    const languages: string[] = ['fr', 'en'];
    const setNewLanguage = (lang: string): void => {
        void i18n.changeLanguage(lang);
    };
    useEffect(() => {
        document.title = i18n.t('page-title');
    }, [i18n.resolvedLanguage]);
    return (
        <div
            className="relative inline-block"
            onBlur={() => {
                setToggleMenuState(false);
            }}
        >
            <button
                className="language-select__button"
                onClick={() => {
                    setToggleMenuState(!isToggled);
                }}
            >
                <div className="flex uppercase">
                    <span>{i18n.resolvedLanguage}</span>
                    <span
                        className={`${
                            isToggled ? 'rotate-180' : 'rotate-0'
                        } ml-2 my-auto transition-all duration-100 ease-in-out`}
                    >
                        <img src={dropArrowIcon} alt="Select down arrow icon" />
                    </span>
                </div>
            </button>
            <div
                className={`language-select__drop-container ${
                    isToggled
                        ? 'visible opacity-100 translate-y-0'
                        : 'invisible opacity-0 -translate-y-2'
                }`}
            >
                <ul className="lg:relative divide-y cursor-pointer shadow-md">
                    {languages.map((language) => {
                        return (
                            <li
                                className="lg:px-0 px-6"
                                onClick={() => {
                                    setNewLanguage(language);
                                    props.closeMenu();
                                }}
                                key={language}
                            >
                                <span className="lg:text-center lg:hover:bg-brown-light block lg:px-0 py-2 uppercase">
                                    {language}
                                </span>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}
