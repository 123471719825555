import { ReactElement, useState, ReactNode } from 'react';
import LanguageSelect from './LanguageSelect';
import burgerMenuIcon from '../../assets/images/burger-menu.svg';

interface surveyNavbarProps {
    children: ReactNode;
}

export default function SurveyNavbar(props: surveyNavbarProps): ReactElement {
    const [isMenuToggled, setToggleMenuState] = useState<boolean>(false);
    return (
        <>
            <div className="navbar py-4 2xl:px-28">
                <div className="px-4 flex lg:hidden">
                    <button
                        className="block my-auto"
                        onClick={() => {
                            setToggleMenuState(!isMenuToggled);
                        }}
                    >
                        <img src={burgerMenuIcon} alt="Burger menu icon" />
                    </button>
                </div>
                {/* margin-right to remove when the logo will be adapted */}
                <div
                    className={`${
                        isMenuToggled
                            ? 'visible opacity-100 translate-y-0'
                            : 'invisible lg:visible opacity-0 lg:opacity-100 -translate-y-2 justify-between lg:translate-y-0'
                    } menu__breadcrumb-container menu__container`}
                >
                    <div
                        className="lg:flex"
                        onClick={() => {
                            setToggleMenuState(false);
                        }}
                    >
                        {props.children}
                    </div>
                    <LanguageSelect
                        closeMenu={() => {
                            setToggleMenuState(false);
                        }}
                    ></LanguageSelect>
                </div>
            </div>
            <div
                className={`menu__negative-space ${
                    isMenuToggled
                        ? 'z-30 visible lg:invisible opacity-20'
                        : 'invisible opacity-0'
                }`}
                onClick={() => {
                    setToggleMenuState(false);
                }}
            ></div>
        </>
    );
}
