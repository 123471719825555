import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import HomePage from './pages/HomePage';
import SurveyPage from './pages/SurveyPage';
import AboutPage from './pages/AboutPage';
import CompanyFormPage from './pages/CompanyFormPage';
import ConfirmationPage from './pages/ConfirmationPage';
import ReportPage from './pages/ReportPage';
import reportWebVitals from './reportWebVitals';
import './i18n';

const router = createBrowserRouter([
    {
        path: '/',
        element: <HomePage />,
        errorElement: <h1>404 Not found</h1>,
    },
    {
        path: '/about',
        element: <AboutPage />,
        errorElement: <h1>404 Not found</h1>,
    },
    {
        path: '/survey',
        element: <SurveyPage />,
        errorElement: <h1>404 Not found</h1>,
    },
    {
        path: '/about-you',
        element: <CompanyFormPage />,
        errorElement: <h1>404 Not found</h1>,
    },
    {
        path: '/thanks',
        element: <ConfirmationPage />,
        errorElement: <h1>404 Not found</h1>,
    },
    {
        path: '/report/:id',
        element: <ReportPage />,
        errorElement: <h1>404 Not found</h1>,
    },
    {
        path: '*',
        element: <h1>404 Not found</h1>,
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
