import { ReactElement } from 'react';

export default function Footer(): ReactElement {
    const START_DATE = 2023;
    const currentYear = new Date().getFullYear();
    const yearsScale =
        currentYear === START_DATE
            ? START_DATE
            : `${START_DATE} - ${currentYear}`;
    return (
        <div className="w-full text-center p-4">
            <p className="text-sm">
                Positive CSR v1.0.0 | {yearsScale} © The Positive Project
            </p>
        </div>
    );
}
