import * as React from 'react';
import Navbar from '../components/navbar/Navbar';
import gradient from '../assets/images/layout/gradient.svg';
import Footer from '../components/footer/Footer';
import PrimaryButton from '../components/button/PrimaryButton';
import { useTranslation, Trans } from 'react-i18next';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Spinner from '../components/Spinner';
import { ReportCategory } from '../types/CSRSurvey';
import SpiderChart from '../components/report/SpiderChart';
import ReportIntro from '../components/report/ReportIntroduction';
import { roundToOneDecimal, average } from '../utils/maths';
import CategoryReport from '../components/report/CategoryReport';

export default function ReportPage(): React.ReactElement {
    const { t, i18n } = useTranslation();

    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [categoriesLabel, setCategoriesLabel] = useState<
        Array<string | string[]>
    >([]);
    const [categoriesNotes, setCategoriesNotes] = useState<number[]>([]);
    const [fetchError, setFetchError] = useState<boolean>(false);
    const [reportData, setReportData] = useState<ReportCategory[] | null>();

    const MAX_NOTE: number = 5;

    useEffect(() => {
        fetchReportData();
    }, [i18n.resolvedLanguage]);

    useEffect(() => {
        const labels: Array<string | string[]> = [];
        const notes: number[] = [];
        if (reportData) {
            Object.values(reportData).forEach((category: ReportCategory) => {
                labels.push(category.title.split(' '));
                notes.push(
                    roundToOneDecimal(
                        (category.user_score / category.max_score) * MAX_NOTE
                    )
                );
            });
            setCategoriesLabel(labels);
            setCategoriesNotes(notes);
        }
    }, [reportData]);

    const fetchReportData = (): void => {
        setFetchError(false);
        setIsFetching(true);
        void axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_ENDPOINT}${window.location.pathname}${window.location.search}&lang=${i18n.resolvedLanguage}`,
            timeout: 4000,
        })
            .then((response) => {
                setIsFetching(false);
                setReportData(response.data);
            })
            .catch(() => {
                setIsFetching(false);
                setFetchError(true);
            });
    };

    return (
        <div className="page h-full">
            <Navbar />
            <div className="page__content h-full lg:grid-cols-2">
                <Spinner isLoading={isFetching} />
                {!fetchError && reportData && (
                    <>
                        <div className="lg:col-span-1 lg:order-last">
                            <div className="relative lg:fixed lg:right-40 2xl:right-80 lg:w-1/3 2xl:w-1/4 h-96 py-6 bg-white rounded-xl shadow-lg">
                                <SpiderChart
                                    labels={categoriesLabel}
                                    data={categoriesNotes}
                                />
                            </div>
                        </div>
                        <div className="mt-10 lg:mt-0 lg:col-span-1">
                            <h1 className="font-regular text-2xl">
                                <Trans i18nKey="pages.report.title">
                                    Sur la base de votre auto-analyse
                                    <br />
                                    <span className="font-bold">
                                        le niveau de maturité de votre
                                        organisation se présente comme suit:
                                    </span>
                                </Trans>
                            </h1>
                            <ReportIntro
                                averageScore={roundToOneDecimal(
                                    average(categoriesNotes)
                                )}
                                maxScore={MAX_NOTE}
                            />
                            {Object.values(reportData).map(
                                (category: ReportCategory) => {
                                    return (
                                        <CategoryReport
                                            label={category.title}
                                            score={roundToOneDecimal(
                                                (category.user_score /
                                                    category.max_score) *
                                                    MAX_NOTE
                                            )}
                                            maxScore={MAX_NOTE}
                                            recommendations={
                                                category.recommendations
                                            }
                                            key={category.title}
                                        />
                                    );
                                }
                            )}
                        </div>
                    </>
                )}
                {fetchError && (
                    <div className="mx-auto text-center">
                        <p className="text-2xl">
                            {t('pages.report.fetch-error')}
                        </p>
                        <PrimaryButton
                            className="mt-10"
                            onClick={fetchReportData}
                        >
                            {t('pages.report.reload-button')}
                        </PrimaryButton>
                    </div>
                )}
            </div>
            <img
                className="-z-50 w-full fixed bottom-0 right-0 select-none"
                src={gradient}
                alt="Background color gradient"
            />
            <Footer />
        </div>
    );
}
